import React, { useEffect, useState, useCallback } from 'react';
import { useExploreQuery } from '@querys/useExploreQuery';
import { useGesture } from 'react-use-gesture';
import cx from 'classnames';
import {
  motion,
  useMotionValue,
  useSpring,
  AnimatePresence,
} from 'framer-motion';
import useEmblaCarousel from 'embla-carousel-react';
import { SanityImage } from '@components/sanity/sanityImage';
import { Link } from 'gatsby';
import { useSanityPath } from '@hooks';

import Vector from '@svg/vector.svg';
import VectorBack from '@svg/vectorback.svg';

const cursorVars = {
  visible: {
    scale: 1,
    opacity: 1,
  },
  dragging: {
    scale: 0.5,
    opacity: 1,
  },
  hidden: {
    scale: 0.2,
    opacity: 0,
  },
};

const cursorTextVars = {
  visible: {
    opacity: 1,
  },
  dragging: {
    opacity: 0,
  },
  hidden: {
    opacity: 0,
  },
};

const PrevButton = ({ enabled, onClick, pageColour }) => (
  <button
    className={cx(
      `transition-all ease-in-out duration-300 mr-4 w-[45px] h-[45px] rounded-[50%] cursor-pointer touch-manipulation `,
      {
        'opacity-20': !enabled,
        'bg-blue/30': pageColour?.title === 'blue' || !pageColour,
        'bg-red/30': pageColour?.title === 'red',
        'bg-navy/30': pageColour?.title === 'navy',
        'bg-dark-red/30': pageColour?.title === 'dark-red',
        'bg-grey/30': pageColour?.title === 'grey',
      },
    )}
    onClick={onClick}
    disabled={!enabled}
    aria-label='next button'>
    <VectorBack
      className={`ml-[16px] w-[11px] h-[17px] svg-${
        pageColour?.title || 'blue'
      }`}
    />
  </button>
);

const NextButton = ({ enabled, onClick, pageColour }) => (
  <button
    className={cx(
      `transition-all ease-in-out duration-300  w-[45px] h-[45px] rounded-[50%] cursor-pointer touch-manipulation `,
      {
        'opacity-20': !enabled,
        'bg-blue/30': pageColour?.title === 'blue' || !pageColour,
        'bg-red/30': pageColour?.title === 'red',
        'bg-navy/30': pageColour?.title === 'navy',
        'bg-dark-red/30': pageColour?.title === 'dark-red',
        'bg-grey/30': pageColour?.title === 'grey',
      },
    )}
    onClick={onClick}
    disabled={!enabled}
    aria-label='next button'>
    <Vector className={`m-auto w-[11px] h-[17px] svg-${pageColour?.title}`} />
  </button>
);

const Card = ({ cardHeader, cardText, cardImage, pageColour, cardLink }) => {
  const path = useSanityPath(cardLink?.page);

  return (
    <Link to={path}>
      <div
        className={`border-t link-overline overflow-hidden pt-5 link-overline-${pageColour?.title}`}>
        <h4 className={`blockH7 mb-[10px] text-${pageColour?.title || 'blue'}`}>
          {cardHeader}
        </h4>

        <p className='text-grey blockH4 mb-10'>{cardText}</p>

        <div className='relative aspect-w-4 aspect-h-3 imageZoom'>
          <SanityImage
            image={cardImage}
            className='absolute top-0 left-0 object-cover w-full h-full'
          />
        </div>
      </div>
    </Link>
  );
};

const ExploreMenu = ({ pageColour }) => {
  const { header, exploreItems } = useExploreQuery();

  const [viewportRef, embla] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
    dragFree: true,
  });

  const [cusorActive, setCursorActive] = useState(false);
  const [isDrag, setDrag] = useState(false);

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();

    embla.on('select', onSelect);
  }, [embla, onSelect]);

  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 40, stiffness: 300 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const bind = useGesture({
    onHover: ({ hovering }) => setCursorActive(hovering),
    onDrag: ({ dragging }) => setDrag(dragging),
    onMove: ({ values }) => {
      cursorX.set(values[0] - 40);
      cursorY.set(values[1] - 40);
    },
  });

  return (
    <>
      <AnimatePresence>
        {cusorActive && (
          <motion.div
            style={{
              width: '80px',
              height: '80px',
              translateX: cursorXSpring,
              translateY: cursorYSpring,
              backgroundColor: pageColour?.value || '#00A0D7',
            }}
            className={`fixed left-0 top-0 rounded-full z-50 pointer-events-none cursor-none flex items-center justify-center drag-cursor  text-white`}
            variants={cursorVars}
            initial='hidden'
            animate={isDrag ? 'dragging' : 'visible'}
            exit='hidden'>
            <motion.span className='' variants={cursorTextVars}>
              &lt; Drag &gt;
            </motion.span>
          </motion.div>
        )}
      </AnimatePresence>

      <div
        className={`px-gutter pt-space-m pb-space-m overflow-hidden  relative`}
        style={{
          backgroundColor:
            pageColour?.title === 'red' || pageColour?.title === 'dark-red'
              ? '#F9F3F4'
              : pageColour?.title === 'grey'
              ? '#F3F3F3'
              : '#E5F6FB',
        }}>
        <div className='mb-4 md:mbx24 md:flex justify-between items-center'>
          <h1
            className={`blockH3 text-${
              pageColour?.title || 'grey'
            } text-center md:text-left mb-5 md:mb-0`}>
            {header}
          </h1>
          <div className='text-center hidden md:block '>
            <PrevButton
              onClick={scrollPrev}
              enabled={prevBtnEnabled}
              pageColour={pageColour}
            />
            <NextButton
              onClick={scrollNext}
              enabled={nextBtnEnabled}
              pageColour={pageColour}
            />
          </div>
        </div>

        <div ref={viewportRef} {...bind()}>
          <div className='flex items-stretch gap-x-10'>
            {exploreItems?.map((item, i) => (
              <div
                className='border-t-2 w-full md:w-1/2 xl:w-1/3 flex-shrink-0 '
                key={i}>
                <Card key={i} {...item} pageColour={pageColour} />
              </div>
            ))}
          </div>
        </div>
        <div className='text-center md:hidden mt-10 '>
          <PrevButton
            onClick={scrollPrev}
            enabled={prevBtnEnabled}
            pageColour={pageColour}
          />
          <NextButton
            onClick={scrollNext}
            enabled={nextBtnEnabled}
            pageColour={pageColour}
          />
        </div>
      </div>
    </>
  );
};

export default ExploreMenu;
