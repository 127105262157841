import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useExploreQuery = () => {
  const { sanityExplore } = useStaticQuery(graphql`
    query {
      sanityExplore {
        header
        exploreItems {
          cardHeader
          cardText
          cardImage {
            ...Image
          }
          cardLink {
            ...PageLink
          }
        }
      }
    }
  `);
  return sanityExplore || {};
};
