import React from 'react';
import { graphql } from 'gatsby';

// Hooks Querys & Context
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import Layout from '@components/layout';

import ExploreMenu from '@components/fixed/exploreMenu';
import { PageMeta } from '@components/pageMeta';

import { SanityImage } from '@components/sanity/sanityImage';
import { Link } from 'gatsby';
import { useArticleQuery } from '../GraphQl/useArticleQuery';

const NewsPage = ({ data: { page } }) => {
  const { seo, title, introText } = page || {};
  const { nodes } = useArticleQuery();

  return (
    <Layout reverseHeader={true}>
      <PageMeta {...seo} />

      <div className='bg-[#E5F6FB] pt-36 xl:pt-96 px-gutter text-grey'>
        <div>
          {' '}
          <h1 className='richH0'>{title}</h1>
          <p className='richH2'>{introText}</p>
        </div>

        <div className=' grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-8 gap-y-12 lg:gap-y-16 md:gap-y-24 pt-20 md:pt-space-m'>
          {nodes?.map((node) => (
            <Link to={`${node?.slug?.current}`} className='group'>
              <h1 className='blockH7 text-blue mb-2 lg:mb-5'>{node?.date}</h1>
              <h1 className='blockH4 mb-2 lg:mb-10'>{node?.title}</h1>
              <p className='blockH8 mb-6 lg:mb-10'>{node?.articleIntro}</p>
              <div className='aspect-w-5 aspect-h-4 relative overflow-hidden  '>
                <SanityImage
                  image={node?.articleImage}
                  className='w-full h-full absolute top-0 left-0 object-cover group-hover:scale-125 transition-all duration-700 ease-in-out'
                />
              </div>
            </Link>
          ))}
        </div>
      </div>

      <ExploreMenu />
    </Layout>
  );
};

export default NewsPage;

export const pageQuery = graphql`
  query newsPageQuery {
    page: sanityNewsHome {
      seo {
        ...SEO
      }
      title
      introText
    }
  }
`;
