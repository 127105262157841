import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useArticleQuery = () => {
  const { allSanityArticle } = useStaticQuery(graphql`
    query {
      allSanityArticle(sort: { fields: date, order: DESC }) {
        nodes {
          title
          slug {
            current
          }
          date(formatString: "DD MMMM, YYYY")
          articleIntro
          articleImage {
            ...ImageWithPreview
          }
          articleContent: _rawArticleContent(
            resolveReferences: { maxDepth: 10 }
          )
        }
      }
    }
  `);
  return allSanityArticle || {};
};
